// src/i18n.ts
import { createI18n } from "vue-i18n";
import es from "vuetify/lib/locale/es";
import esLocale from "@/locales/es.json";

const messages = {
  es: {
    ...esLocale,
    $vuetify: es
  }
};

const i18n = createI18n({
  legacy: false,
  locale: process.env.VUE_APP_I18N_LOCALE || "es",
  messages
});

export default i18n;
