import { gql } from "graphql-tag";
import { provideApolloClient, useQuery } from "@vue/apollo-composable";
import apolloClient from "@/plugins/apollo-client";
import {User} from "@/models/users";
import store from "@/plugins/store/store";

provideApolloClient(apolloClient);

const GQL_ME = gql`
  query MeUser {
    auth {
      me {
        email
        fullName
        id
        phoneNumber
        accessLevel
      }
    }
  }
`;

const GQL_LOAD_USERS = gql`
  query Users {
    users {
      id
      fullName
      email
      phoneNumber
      active
      accessLevel
    }
  }
`;

const GQL_CREATE_USER = gql`
  mutation CreateUser($userCreationInput: UserCreationInput!) {
    users {
      createUser(userInput: $userCreationInput) {
        accessLevel
        active
        email
        fullName
        id
        phoneNumber
      }
    }
  }
`;

const GQL_UPDATE_USER = gql`
  mutation UpdateUser($id: UUID!, $userInput: UserInput!) {
    users {
      updateUser(id: $id, userInput: $userInput) {
        id
        fullName
        email
        phoneNumber
        active
        accessLevel
      }
    }
  }
`;


const DELETE_USER = gql`
  mutation DeleteUser($id: UUID!) {
    users {
      deleteUser(id: $id)
    }
  }
`;

export const users = {
  me: async function (): Promise<User> {
    try {
      const { data } = await apolloClient.query({
        query: GQL_ME,
        fetchPolicy: "no-cache",
      });

      return data.auth.me;
    } catch (error) {
      store.dispatch("notificator/errorResponse", error);
      throw error;
    }
  },

  load: async function (): Promise<User[]> {
    try {
      const { data } = await apolloClient.query({
        query: GQL_LOAD_USERS,
        fetchPolicy: "no-cache",
      });

      return data.users;
    } catch (error) {
      store.dispatch("notificator/errorResponse", error);
      throw error;
    }
  },
  getUser: async function (id: string): Promise<User | undefined> {
    try {
      const { data } = await apolloClient.query({
        query: GQL_LOAD_USERS,
        fetchPolicy: "no-cache",
      });

      const user = data.users.find((user: User) => user.id === id);
      return user;
    } catch (error) {
      store.dispatch("notificator/errorResponse", error);
      throw error;
    }
  },

  create: async function (userCreationInput: {
    fullName: string;
    email: string;
    phoneNumber: string;
    level: string;
    active: boolean;

  }): Promise<User> {
    try {
      const { data } = await apolloClient.mutate({
        mutation: GQL_CREATE_USER,
        variables: { userCreationInput },
      });

      return data.users.createUser;
    } catch (error) {
      store.dispatch("notificator/errorResponse", error);
      throw error;
    }
  },
  update: async function (
    id: string,
    userInput: {
      fullName: string;
      email: string;
      phoneNumber: string;
      active: boolean;
      level: string;
    }
  ): Promise<User> {
    try {
      const { data } = await apolloClient.mutate({
        mutation: GQL_UPDATE_USER,
        variables: { id, userInput },
      });
      return data.users.updateUser;
    } catch (error) {
      store.dispatch("notificator/errorResponse", error);
      throw error;
    }
  },

  delete: async function (id: string): Promise<boolean> {
    try {
      const { data } = await apolloClient.mutate({
        mutation: DELETE_USER,
        variables: { id },
      });

      const result = data.users.deleteUser;

      return result;
    } catch (error) {
      store.dispatch("notificator/errorResponse", error);
      throw error;
    }
  },
};

export default users;
