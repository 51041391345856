<template>
  <v-snackbar v-for="message in messages" :key="message.id" :model-value="message.visible"
    @update:model-value="hideMessage(message.id)" :color="message.type" class="top-snackbar" rounded>
    {{ message.message }}
    <v-btn color="#error" variant="outlined" flat type="text" @click="hideMessage(message.id)" class="ml-10">
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </v-snackbar>
  <v-app id="app">
    <v-container fluid class="py-0 px-0">
      <router-view></router-view>
    </v-container>
    <v-overlay :model-value="isLoading" class="d-flex align-center justify-center">
      <v-progress-circular indeterminate color="primary" />
    </v-overlay>
  </v-app>
</template>

<script lang="ts">
import { defineComponent, computed, watch, nextTick, ref } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: "App",
  setup() {
    const store = useStore();
    const messages = computed(() => store.getters['notificator/messages']);
    const isLoading = computed(() => store.state.isLoading)

    const hideMessage = (id: string) => {
      store.dispatch('notificator/remove', id)
    }

    return {
      messages,
      hideMessage,
      isLoading
    };
  },
});
</script>

<style lang="scss">
@import "./scss/layout.scss";

.top-snackbar{
  top: 60px !important;
  bottom:auto !important;
}

.v-snackbar_wrapper {
  z-index: 1000;
}
.v-snackbar_content {
  display: flex;
  align-items: center;

}
.v-overlay {
  z-index: 500;
}
</style>