import i18n from "../../i18n";
import { v4 as uuidv4 } from "uuid";
import router from "@/router";

interface Messages {
  id: string;
  message: string;
  type: "error" | "info" | "warning" | "success";
  visible: boolean;
}

interface State {
  messages: Messages[];
}
interface Payload {
  message: string;
  type: "error" | "info" | "warning" | "success";
}

const state: State = {
  messages: [],
};

const getters = {
  messages: (state: State) => state.messages,
};

const mutations = {
  addMessage(state: State, payload: Payload) {
    let message = payload.message ?? "";
    if (message.trim() === "") {
      return;
    }
    const newMessage = {
      id: uuidv4(),
      message: message,
      type: payload.type,
      visible: true,
    };
    state.messages.push(newMessage);
  },

  hideMessage(state: State, id: string) {
    const message = state.messages.find((message) => message.id === id);
    if (message) {
      message.visible = false;
    }
  },
  removeMessage(state: State, messageId: string) {
    const index = state.messages.findIndex(
      (message) => message.id === messageId
    );
    if (index !== -1) {
      state.messages.splice(index, 1);
    }
  },
};
const actions = {
  addMessage: ({ commit }: { commit: any }, payload: Payload) => {
    commit("addMessage", payload);
  },
  hideMessage: ({ commit }: { commit: any }, id: string) => {
    commit("hideMessage", id);
  },
  info: ({ commit }: { commit: any }, message: string) => {
    commit("addMessage", { message: message, type: "info" });
  },
  warning: ({ commit }: { commit: any }, message: string) => {
    commit("addMessage", { message: message, type: "warning" });
  },
  success: ({ commit }: { commit: any }, message: string) => {
    commit("addMessage", { message: i18n.global.t(message), type: "success" });
  },
  error: ({ commit }: { commit: any }, message: string) => {
    commit("addMessage", { message: i18n.global.t(message), type: "error" });
  },
  remove: ({ commit }: { commit: any }, index: number) => {
    commit("removeMessage", index);
  },
  errorResponse: async (
    { commit, dispatch }: { commit: any; dispatch: any },
    error: any
  ) => {
    const TIMEOUT_ERROR = "timeout";
    const ACCESS_DENIED_ERROR = "access_denied";
    const SERVER_ERROR = "server_error";
    const INVALID_CREDENTIALS = "invalid_credentials";

    let customMessage = error.customMessage || i18n.global.t(SERVER_ERROR);
    if (!error || (error.statusText && error.statusText === TIMEOUT_ERROR)) {
      await commit("addMessage", {
        message: i18n.global.t(SERVER_ERROR),
        type: "error",
      });
    } else if (customMessage === ACCESS_DENIED_ERROR) {
      await commit("addMessage", {
        message: i18n.global.t(ACCESS_DENIED_ERROR),
        type: "error",
      });
    } else if (error.graphQLErrors && error.graphQLErrors.length > 0) {
      const graphQLError = error.graphQLErrors[0];
      if (graphQLError.message === INVALID_CREDENTIALS) {
        customMessage = INVALID_CREDENTIALS;
      }
      await commit("addMessage", {
        message: i18n.global.t(customMessage),
        type: "error",
      });
    } else if (
      error.networkError &&
      error.networkError.result &&
      Array.isArray(error.networkError.result.errors) &&
      error.networkError.result.errors.length > 0
    ) {
      const networkError = error.networkError.result.errors[0];
      if (networkError.message === "Invalid username or password.") {
        customMessage = "error_login";
      }
      await commit("addMessage", {
        message: i18n.global.t(customMessage),
        type: "error",
      });
    } else if (error.networkError && error.networkError.statusCode) {
      if (error.networkError.statusCode === 403) {
        await commit("addMessage", {
          message: i18n.global.t("access-denied"),
          type: "error",
        });
      } else if (error.networkError.statusCode === 404) {
        await commit("addMessage", {
          message: i18n.global.t("not-found-response"),
          type: "error",
        });
      } else if (error.networkError.statusCode === 401) {
        await dispatch('logout');
        router.push('/login');
      }
    }
  }
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
