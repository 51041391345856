import { gql } from "graphql-tag";
import { provideApolloClient, useQuery } from "@vue/apollo-composable";
import apolloClient from "@/plugins/apollo-client";
import { Municipality } from "@/models/locations";
import store from "@/plugins/store/store";

provideApolloClient(apolloClient);
const GQL_ALL_MUNICIPALITIES = gql`
  query municipalities {
    location {
      municipalities {
        id
        name
        stateId
        state {
          countryId
          id
          name
          country {
            active
            id
            name
            phoneCode
            sortname
          }
        }
      }
    }
  }
`;

const GQL_MUNICIPALITIES_BY_STATE = gql`
  query municipalities($stateId: String!) {
    location {
      municipalities(stateId: $stateId) {
        stateId
        id
        name
        state {
          countryId
          id
          name
          country {
            active
            id
            name
            phoneCode
            sortname
          }
        }
      }
    }
  }
`;

export const municipalities = {
  load: async function (stateId: string = ""): Promise<Municipality[]> {
    try {
      let query;
      let variables;

      if (stateId) {
        query = GQL_MUNICIPALITIES_BY_STATE;
        variables = { stateId };
      } else {
        query = GQL_ALL_MUNICIPALITIES;
        variables = {};
      }

      const { data } = await apolloClient.query({
        query,
        variables,
        fetchPolicy: "no-cache",
      });

      return data.location.municipalities;
    } catch (error) {
      store.dispatch("notificator/errorResponse", error);
      throw error;
    }
  },
};
export default municipalities;