import { gql } from "graphql-tag";
import { provideApolloClient } from "@vue/apollo-composable";
import { Category, SubCategory } from "@/models/categories";
import apolloClient from "@/plugins/apollo-client";
import store from "@/plugins/store/store";

provideApolloClient(apolloClient);

const GQL_ALL_CATEGORIES = gql`
  query categories {
    market {
      categories {
        active
        id
        name
        subCategories {
          active
          categoryId
          id
          name
        }
      }
    }
  }
`;

const GQL_CREATE_CATEGORY_MUTATION = gql`
  mutation CreateCategory($input: CategoryInput!) {
    categories {
      create(input: $input) {
        id
        name
        active
      }
    }
  }
`;

const GQL_UPDATE_CATEGORY_MUTATION = gql`
  mutation UpdateCategory($id: UUID!, $input: UpdateCategoryInput!) {
    categories {
      update(id: $id, input: $input) {
        id
        name
        active
      }
    }
  }
`;
const GQL_DELETE_CATEGORY_MUTATION = gql`
  mutation DeleteCategory($id: UUID!) {
    categories {
      delete(id: $id)
    }
  }
`;

const GQL_CREATE_SUBCATEGORY_MUTATION = gql`
  mutation CreateSubCategory($input: SubCategoryInput!) {
    subCategories {
      create(input: $input) {
        active
        categoryId
        id
        name
      }
    }
  }
`;

const GQL_DELETE_SUBCATEGORY_MUTATION = gql`
  mutation DeleteSubCategory($id: UUID!) {
    subCategories {
      delete(id: $id)
    }
  }
`;

const GQL_UPDATE_SUBCATEGORY_MUTATION = gql`
  mutation UpdateSubCategory($id: UUID!, $input: UpdateSubCategoryInput!) {
    subCategories {
      update(id: $id, input: $input) {
        active
        categoryId
        id
        name
      }
    }
  }
`;



export const categories = {
  load: async function (): Promise<Category[]> {
    const { data } = await apolloClient.query({
      query: GQL_ALL_CATEGORIES,
      fetchPolicy: "no-cache",
    });
    return data.market.categories;
  },
  create: async function (input: {
    active: boolean;
    name: string;
  }): Promise<Category> {
    const { data } = await apolloClient.mutate({
      mutation: GQL_CREATE_CATEGORY_MUTATION,
      variables: {
        input,
      },
    });
    return data.categories.create;
  },
  update: async function (
    id: string,
    input: { active: boolean; name: string }
  ): Promise<Category> {
    const { data } = await apolloClient.mutate({
      mutation: GQL_UPDATE_CATEGORY_MUTATION,
      variables: {
        id,
        input,
      },
    });
    return data.categories.update;
  },
  delete: async function (id: string): Promise<boolean> {
    const { data } = await apolloClient.mutate({
      mutation: GQL_DELETE_CATEGORY_MUTATION,
      variables: {
        id,
      },
    });
    return data.categories.delete;
  },
};

export const subCategories = {
  create: async function (input: {
    active: boolean;
    categoryId: string;
    name: string;
  }): Promise<SubCategory> {
    const { data } = await apolloClient.mutate({
      mutation: GQL_CREATE_SUBCATEGORY_MUTATION,
      variables: {
        input,
      },
    });
    return data.subCategories.create;
  },
  update: async function (
    id: string,
    input: { active: boolean; categoryId: string; name: string }
  ): Promise<SubCategory> {
    const { data } = await apolloClient.mutate({
      mutation: GQL_UPDATE_SUBCATEGORY_MUTATION,
      variables: {
        id,
        input,
      },
    });
    return data.subCategories.update;
  },
  delete: async function (id: string): Promise<boolean> {
    const { data } = await apolloClient.mutate({
      mutation: GQL_DELETE_SUBCATEGORY_MUTATION,
      variables: {
        id,
      },
    });
    return data.subCategories.delete;
  },
};

export default categories; subCategories;
