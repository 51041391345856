import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_overlay = _resolveComponent("v-overlay")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (message) => {
      return (_openBlock(), _createBlock(_component_v_snackbar, {
        key: message.id,
        "model-value": message.visible,
        "onUpdate:modelValue": ($event: any) => (_ctx.hideMessage(message.id)),
        color: message.type,
        class: "top-snackbar",
        rounded: ""
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(message.message) + " ", 1),
          _createVNode(_component_v_btn, {
            color: "#error",
            variant: "outlined",
            flat: "",
            type: "text",
            onClick: ($event: any) => (_ctx.hideMessage(message.id)),
            class: "ml-10"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, null, {
                default: _withCtx(() => [
                  _createTextVNode("mdi-close")
                ]),
                _: 1
              })
            ]),
            _: 2
          }, 1032, ["onClick"])
        ]),
        _: 2
      }, 1032, ["model-value", "onUpdate:modelValue", "color"]))
    }), 128)),
    _createVNode(_component_v_app, { id: "app" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_container, {
          fluid: "",
          class: "py-0 px-0"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_router_view)
          ]),
          _: 1
        }),
        _createVNode(_component_v_overlay, {
          "model-value": _ctx.isLoading,
          class: "d-flex align-center justify-center"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_progress_circular, {
              indeterminate: "",
              color: "primary"
            })
          ]),
          _: 1
        }, 8, ["model-value"])
      ]),
      _: 1
    })
  ], 64))
}