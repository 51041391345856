import Cookies from "js-cookie";
import { login } from "@/services/authService";
import { Router } from "vue-router";

type State = {
  token: string | null;
  authTokenExpires: string | null;
};

const state: State = {
  token: Cookies.get("auth-token") || null,
  authTokenExpires: Cookies.get("auth-token-expires") || null,
};

const mutations = {
  setToken(state: State, token: string) {
    state.token = token;
    Cookies.set("auth-token", token, { secure: true, sameSite: "Strict" });
  },
  removeToken(state: State) {
    state.token = null;
    Cookies.remove("auth-token");
  },
};

const getters = {
  isAuthenticated: (state: State) => !!state.token,
};

const actions = {
  async login(
    { commit }: { commit: any },
    { email, password }: { email: string; password: string }
  ) {
    try {
      const response = await login(email, password);

      if (
        response &&
        response.data &&
        response.data.auth &&
        response.data.auth.login
      ) {
        const token = response.data.auth.login.authToken;
        commit("setToken", token);
      }
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error);
    }
  },

  logout({ commit }: { commit: any }, router: Router) {
    commit("removeToken");
    router.push({ name: "UserLogin" });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
