import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";
import Cookies from "js-cookie";

const httpLink = createHttpLink({
  uri: process.env.VUE_APP_GRAPHQL_URL,
  fetch,
  fetchOptions: {
    setTimeout: process.env.VUE_APP_FETCH_TIMEOUT,
  },
});

const authLink = setContext((_, { headers }) => {
  const token = Cookies.get("auth-token");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const cache = new InMemoryCache();

const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
});

export default apolloClient;
