import { ActionContext } from "vuex";
import { Municipality } from "@/models/locations";
import municipalitiesService from "@/services/municipalitiesService";
import { useStore } from "vuex";

interface State {
  municipalities: Municipality[];
}

export default {
  state: {
    municipalities: [],
  } as State,
  mutations: {
    setMunicipalities(state: State, municipalities: Municipality[]) {
      state.municipalities = municipalities;
    },
  },
  actions: {
    async loadMunicipalities({ commit, dispatch, }: { commit: any; dispatch: any; }) {
      dispatch("SET_LOADING", true, { root: true });
      const store = useStore();
      try {
        const municipalities: Municipality[] =
          await municipalitiesService.load();
        commit("setMunicipalities", municipalities);
      } catch (error) {
        store.dispatch("notificator/errorResponse", error);
      } finally {
        dispatch("SET_LOADING", false, { root: true });
      }
    },
  },
  getters: {
    getMunicipalityById: (state: State) => (id: string) => {
      return (
        state.municipalities.find((municipality) => municipality.id === id) ||
        null
      );
    },
    getMunicipalitiesByStateId: (state: State) => (stateId: string) => {
      return (
        state.municipalities.filter(
          (municipality) => municipality.stateId === stateId
        ) || []
      );
    },
  },
};
