import { createRouter, createWebHistory, RouteRecordRaw, createWebHashHistory } from "vue-router";
import store from "@/plugins/store/store";
import i18n from "@/plugins/i18n";


const routes: Array<RouteRecordRaw> = [
  {
    path: "/password-recover",
    name: "password-recover",
    component: () =>
      import(/* webpackMode: "lazy" */ "../views/Auth/PasswordRecover.vue"),
    meta: {
      title: "recover",
    },
  },
  {
    path: "/login",
    name: "UserLogin",
    component: () =>
      import(/* webpackMode: "lazy" */ "../views/Auth/UserLogin.vue"),
    meta: {
      title: "login",
    },
  },
  {
    path: "/",
    component: () => import(/* webpackMode: "lazy" */ "../views/Base.vue"),
    meta: {
      title: "Welcome",
      auth: true,
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import(/* webpackMode: "lazy" */ "../views/Home.vue"),
        meta: {
          title: "welcome",
          auth: true,
        },
      },
      {
        path: "/users",
        name: "users",
        component: () =>
          import(/* webpackMode: "lazy" */ "../views/Users/Index.vue"),
        meta: {
          title: "users",
          auth: true,
        },
      },
      {
        path: "/users-create",
        name: "users-create",
        component: () =>
          import(/* webpackMode: "lazy" */ "../views/Users/Form.vue"),
        meta: {
          title: "users-.new",
          auth: true,
        },
      },
      {
        path: "/users/:id/edit",
        name: "users-edit",
        component: () =>
          import(/* webpackMode: "lazy" */ "@/views/Users/Form.vue"),
        meta: {
          title: "users-.edit",
          auth: true,
        },
      },
      {
        path: "/users/:id/details",
        name: "users-details",
        component: () =>
          import(/* webpackMode: "lazy" */ "@/views/Users/Details.vue"),
        meta: {
          title: "users-.details",
          auth: true,
        },
      },

      {
        path: "/states",
        name: "states",
        component: () =>
          import(/* webpackMode: "lazy" */ "../views/Market/State.vue"),
        meta: {
          title: "states",
          auth: true,
        },
      },
      {
        path: "/municipalities",
        name: "municipalities",
        component: () =>
          import(
            /* webpackMode: "lazy" */ "../views/Market/Municipalities.vue"
          ),
        meta: {
          title: "municipalities",
          auth: true,
        },
      },
      {
        path: "/products",
        name: "products",
        component: () =>
          import(/* webpackMode: "lazy" */ "../views/Market/Products.vue"),
        meta: {
          title: "products",
          auth: true,
        },
      },
      {
        path: "/products/:id/details",
        name: "products-details",
        component: () =>
          import(/* webpackMode: "lazy" */ "@/views/Market/ProductDetails.vue"),
        meta: {
          title: "products-.details",
          auth: true,
        },
      },
      {
        path: "/products/:id/edit",
        name: "products-edit",
        component: () =>
          import(/* webpackMode: "lazy" */ "@/views/Market/ProductForm.vue"),
        meta: {
          title: "products-.edit",
          auth: true,
        },
      },
      {
        path: "/categories",
        name: "categories",
        component: () =>
          import(/* webpackMode: "lazy" */ "@/views/Market/Categories.vue"),
        meta: {
          title: "categories",
          auth: true,
        },
      },
      {
        path: "/categories-create",
        name: "categories-create",
        component: () =>
          import(
            /* webpackMode: "lazy" */ "../views/Market/CategoriesForm.vue"
          ),
        meta: {
          title: "categories-.new",
          auth: true,
        },
      },
      {
        path: "/categories-edit/:id",
        name: "categories-edit",
        component: () =>
          import(
            /* webpackMode: "lazy" */ "../views/Market/CategoriesForm.vue"
          ),
        meta: {
          title: "categories-.edit",
          auth: true,
        },
      },
      {
        path: "/subCategories",
        name: "subCategories",
        component: () =>
          import(/* webpackMode: "lazy" */ "@/views/Market/SubCategories.vue"),
        meta: {
          title: "sub-categories",
          auth: true,
        },
      },
      {
        path: "/subCategories-create",
        name: "subCategories-create",
        component: () =>
          import(
            /* webpackMode: "lazy" */ "@/views/Market/SubCategoriesForm.vue"
          ),
        meta: {
          title: "sub-categories",
          auth: true,
        },
      },
      {
        path: "/subCategories-edit/:id",
        name: "subCategories-edit",
        component: () =>
          import(
            /* webpackMode: "lazy" */ "../views/Market/SubCategoriesForm.vue"
          ),
        meta: {
          title: "sub-categories-.edit",
          auth: true,
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.auth)) {
    if (!store.getters["auth/isAuthenticated"]) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

router.afterEach((to) => {
  const routerName = to.meta.title || toString.name;
  const translatedRouteName = i18n.global.t(routerName);
  document.title = ` ${translatedRouteName} | Conexen Admin` ;
})

export default router;
