import { gql } from "graphql-tag";
import { provideApolloClient, useQuery } from "@vue/apollo-composable";
import apolloClient from "@/plugins/apollo-client";
import { State } from "@/models/locations";
import store from "@/plugins/store/store";

provideApolloClient(apolloClient);


const GQL_STATES = gql`
  query States {
    location {
      states {
        countryId
        id
        name
        country {
          active
          id
          name
          phoneCode
          sortname
        }
      }
    }
  }
`;

export const states = {
  load: async function (): Promise<State[]> {
    try {
      const { data } = await apolloClient.query({
        query: GQL_STATES,
        fetchPolicy: "no-cache",
      });

      return data.location.states;
    } catch (error) {
      store.dispatch("notificator/errorResponse", error);
      throw error;
    }
  },
};

export default states;
