import { ActionContext } from "vuex";
import { Category, SubCategory } from "@/models/categories";
import categoriesService from "@/services/categoryService";
import store from "../store";

interface State {
  categories: Category[];
}

export default {
  state: {
    categories: [],
  },
  mutations: {
    setCategories(state: State, categories: Category[]) {
      state.categories = categories.map((category) => ({
        ...category,
        subCategories: category.subCategories || [],
      }));
    },
    addCategory(state: State, category: Category) {
      state.categories.push(category);
    },
    addSubCategory(
      state: State,
      {
        categoryId,
        subCategory,
      }: { categoryId: string; subCategory: SubCategory }
    ) {
      const categoryIndex = state.categories.findIndex(
        (category) => category.id === categoryId
      );
      if (categoryIndex !== -1) {
        state.categories[categoryIndex].subCategories.push(subCategory);
      }
    },
    updateCategory(state: State, updatedCategory: Category) {
      const index = state.categories.findIndex(
        (category) => category.id === updatedCategory.id
      );
      if (index !== -1) {
        state.categories[index] = updatedCategory;
      }
    },
    clearCategories(state: State) {
      state.categories = [];
    },
    deleteCategory(state: State, id: string) {
      state.categories = state.categories.filter(
        (category) => category.id !== id
      );
    },
  },
  actions: {
    async loadCategories({ commit, dispatch }: { commit: any; dispatch: any }) {
      dispatch("SET_LOADING", true, { root: true });
      try {
        const result = await categoriesService.load();
        if (result) {
          commit("setCategories", result);
        }
        return Promise.resolve(result);
      } catch (error: any) {
        return Promise.reject(error);
      } finally {
        dispatch("SET_LOADING", false, { root: true });
      }
    },
    async create(
      { commit, dispatch }: { commit: any; dispatch: any },
      categoryInput: any
    ) {
      dispatch("SET_LOADING", true, { root: true });
      try {
        const result = await categoriesService.create(categoryInput);
        if (result) {
          commit("addCategory", result);
        }
        return result;
      } catch (error) {
        store.dispatch("notificator/errorResponse", error);
        throw error;
      } finally {
        dispatch("SET_LOADING", false, { root: true });
      }
    },
    async update(
      { commit, dispatch }: { commit: any; dispatch: any },
      { id, categoryInput }: { id: string; categoryInput: any }
    ) {
      dispatch("SET_LOADING", true, { root: true });
      try {
        const result = await categoriesService.update(id, categoryInput);
        if (result) {
          commit("updateCategory", result);
        }
        return { result };
      } catch (error) {
        store.dispatch("notificator/errorResponse", error);
        throw error;
      } finally {
        dispatch("SET_LOADING", false, { root: true });
      }
    },
    async remove({ dispatch }: { dispatch: any }, id: string) {
      dispatch("SET_LOADING", true, { root: true });
      try {
        const result = await categoriesService.delete(id);
        if (result) {
          await new Promise((resolve) => setTimeout(resolve, 1000));
          await dispatch("loadCategories");
        }
      } catch (error) {
        store.dispatch("notificator/errorResponse", error);
      } finally {
        dispatch("SET_LOADING", false, { root: true });
      }
    },
  },
  getters: {
    categories(state: State): Category[] {
      return state.categories;
    },
    getCategory: (state: State) => (id: string) => {
      return state.categories.find((category) => category.id === id);
    },
    subCategories(state: State): SubCategory[] {
      return state.categories.flatMap((category) => category.subCategories);
    },
  },
};
