import userService, { users } from "@/services/userService";
import store from "@/plugins/store/store";

type State = {
  users: any[] | null;
};

const state: State = {
  users: [],
};

const mutations = {
  setUsers(state: State, users: any[]) {
    state.users = users;
  },
  clearUsers(state: State) {
    state.users = [];
  },
  deleteUser(state: State, id: string) {
    if (state.users) {
      state.users = state.users.filter((user) => user.id !== id);
    }
  },
};

const getters = {
  usersInfo: (state: State) => state.users,
};

const actions = {
  async loadUsers({ commit, dispatch }: { commit: any; dispatch: any }) {
    try {
      const result = await users.load();

      if (result) {
        commit("setUsers", result);
      }

      return Promise.resolve(result);
    } catch (error: any) {
      return Promise.reject(error);
    } 
  },
  async remove({ dispatch }: { dispatch: any }, id: string) {
    dispatch("SET_LOADING", true, { root: true });
    try {
      const result = await userService.delete(id);

      if (result) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        await dispatch("loadUsers");
      }
    } catch (error) {
      store.dispatch("notificator/errorResponse", error);
    } finally {
      dispatch("SET_LOADING", false, { root: true });
    }
  },
  async create({ dispatch }: { dispatch: any }, userInput: any) {
    dispatch("SET_LOADING", true, { root: true });
    try {
      const result = await userService.create(userInput);
      if (result) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        await dispatch("loadUsers");
      }
      return result;
    } catch (error) {
      store.dispatch("notificator/errorResponse", error);
      throw error;
    } finally {
      dispatch("SET_LOADING", false, { root: true });
    }
  },
  async update(
    { dispatch }: { dispatch: any },
    { id, userInput }: { id: string; userInput: any }
  ) {
    dispatch("SET_LOADING", true, { root: true });
    try {
      const result = await userService.update(id, userInput);
      if (result) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        await dispatch("loadUsers");
      }

      return { result };
    } catch (error) {
      store.dispatch("notificator/errorResponse", error);
      throw error;
    } finally {
      dispatch("SET_LOADING", false, { root: true });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
