import { ActionContext } from "vuex";
import { SubCategory } from "@/models/categories";
import { subCategories as subCategoriesService } from "@/services/categoryService";
import store from "../store";

interface State {
  subCategories: SubCategory[];
}

export default {
  state: {
    subCategories: [],
  },
  mutations: {
    setSubCategories(state: State, subCategories: SubCategory[]) {
      state.subCategories = subCategories;
    },
    addSubCategory(state: State, subCategory: SubCategory) {
      state.subCategories.push(subCategory);
    },
    updateSubCategory(state: State, updatedSubCategory: SubCategory) {
      const index = state.subCategories.findIndex(
        (subCategory) => subCategory.id === updatedSubCategory.id
      );
      if (index !== -1) {
        state.subCategories[index] = updatedSubCategory;
      }
    },
    deleteSubCategory(state: State, subCategoryId: string) {
      state.subCategories = state.subCategories.filter(
        (subCategory) => subCategory.id !== subCategoryId
      );
    },
  },
  actions: {
    async createSubCategory(
      { commit }: ActionContext<State, any>,
      subCategoryInput: { active: boolean; categoryId: string; name: string }
    ) {
      try {
        const createdSubCategory: SubCategory =
          await subCategoriesService.create(subCategoryInput);
        commit("addSubCategory", createdSubCategory);
      } catch (error) {
        store.dispatch("notificator/errorResponse", error);
      }
    },
    async updateSubCategory(
      { commit }: ActionContext<State, any>,
      {
        id,
        input,
      }: {
        id: string;
        input: { active: boolean; categoryId: string; name: string };
      }
    ) {
      try {
        const updatedSubCategory: SubCategory =
          await subCategoriesService.update(id, input);
        commit("updateSubCategory", updatedSubCategory);
      } catch (error) {
        store.dispatch("notificator/errorResponse", error);
      }
    },
    async deleteSubCategory({ commit }: ActionContext<State, any>, id: string) {
      try {
        const result: boolean = await subCategoriesService.delete(id);
        if (result) {
          commit("deleteSubCategory", id);
        }
      } catch (error) {
        store.dispatch("notificator/errorResponse", error);
      }
    },
  },
};
