import { ActionContext } from "vuex";
import { State as StateModel } from "@/models/locations";
import statesService from "@/services/stateService";
import store from "@/plugins/store/store";

interface State {
  states: StateModel[];
}

export default {
  state: {
    states: [],
  } as State,
  mutations: {
    setStates(state: State, states: StateModel[]) {
      state.states = states;
    },
  },
  actions: {
    async loadStates({ commit, dispatch }: { commit: any; dispatch: any }) {
      dispatch("SET_LOADING", true, { root: true });
      try {
        const states: StateModel[] = await statesService.load();
        commit("setStates", states);
      } catch (error) {
        store.dispatch("notificator/errorResponse", error);
      } finally {
        dispatch("SET_LOADING", false, { root: true });
      }
    },
  },
  getters: {
    getStateById: (state: State) => (id: string) => {
      return state.states.find((state) => state.id === id) || null;
    },
  },
};
