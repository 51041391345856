import { gql } from "graphql-tag";
import  apolloClient  from "@/plugins/apollo-client";
import { provideApolloClient, useMutation } from "@vue/apollo-composable";
import  store  from "@/plugins/store/store";


const GQL_LOGIN_MUTATION = gql`
  mutation AuthMutation(
    $loginInput: LoginPinInput!
    $requestLoginInput: LoginInput!
  ) {
    auth {
      login(input: $loginInput) {
        authToken
        user {
          accessLevel
        }
      }
      requestLogin(input: $requestLoginInput)
    }
  }
`;


class CustomError extends Error {
  customMessage?: string;
}

export async function login(email: string, password: string) {
  try {
    provideApolloClient(apolloClient);

    const variables = {
      loginInput: {
        phoneNumber: email,
        pin: parseInt(password),
        agree: true,
      },
      requestLoginInput: {
        phoneNumber: email,
        agree: true,
      },
    };

    const { mutate } = useMutation(GQL_LOGIN_MUTATION);
const result = await mutate(variables);

if (result?.data?.auth?.login?.user?.accessLevel !== "admin") {
  const error = new CustomError("authorization_error");
  error.customMessage = "access_denied";
  await store.dispatch("notificator/errorResponse", error);
  throw error;
}

return result;
  } catch (error: any) {
    if (
      error.networkError &&
      error.networkError.result &&
      error.networkError.result.errors
    ) {
      error.customMessage = "server_error";
      await store.dispatch("notificator/errorResponse", error);
    } else {
      error.customMessage = "network_error";
      await store.dispatch("notificator/errorResponse", error);
    }
  }
}